var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',{staticClass:"role-permissions",attrs:{"mutation":_vm.UPDATE_ROLE_PERMISSIONS_MUTATION,"variables":{
    input: {
      roleId: (_vm.permissionSettings.role && _vm.permissionSettings.role.id) || null,
      permissions: _vm.permissionSettings.permissions || null,
    },
  },"update":_vm.updateRolePermissions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var updateLoading = ref.loading;
  var updateError = ref.error;
return [(!_vm.loading && !updateLoading && (_vm.error || updateError))?_c('alert',{attrs:{"error":_vm.error || updateError}}):_vm._e(),_c('role-form',_vm._b({on:{"submit":function($event){return mutate()}}},'role-form',{
        loading: _vm.loading || updateLoading,
        permissionSettings: _vm.permissionSettings,
        rolesWithPermissions: _vm.rolesWithPermissions,
      },false))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }