<template>
  <div class="settings">
    <v-row class="mb-6"
      ><v-col><h1>Settings</h1></v-col></v-row
    >
    <v-row no-gutters><role-permissions></role-permissions></v-row>
  </div>
</template>
<script>
import RolePermissions from '@/components/RolePermissions/RolePermissions.vue';
export default {
  name: 'Settings',
  components: { RolePermissions },
};
</script>
