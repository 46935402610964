import ROLES_WITH_PERMISSIONS from '@/graphql/queries/rolesWithPermissions.gql';
import PERMISSIONS_DICTIONARY_QUERY from '@/graphql/queries/permissionsDictionary.gql';
import UPDATE_ROLE_PERMISSIONS_MUTATION from '@/graphql/mutations/updateRolePermissions.gql';
import eventHub from '@/utils/eventHub';
import RoleForm from '@/components/RolePermissions/RoleForm/RoleForm.vue';

export default {
  name: 'RolePermissions',
  components: { RoleForm },
  apollo: {
    rolesWithPermissions: {
      query: ROLES_WITH_PERMISSIONS,
      fetchPolicy: 'cache-first',
      loadingKey: 'loading',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load permissions list.' } : error;
      },
    },
    permissionsDictionary: {
      query: PERMISSIONS_DICTIONARY_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load permission definition list.' } : error;
      },
    },
  },
  data: () => ({
    UPDATE_ROLE_PERMISSIONS_MUTATION,
    rolesWithPermissions: null,
    permissionsDictionary: null,
    permissionSettings: { role: null, permissions: null },
    loading: false,
    error: null,
  }),
  methods: {
    updateRolePermissions(store, { data: { updateRolePermissions } }) {
      if (updateRolePermissions) {
        // Read the data from our cache for this query.
        const data = store.readQuery({
          query: ROLES_WITH_PERMISSIONS,
        });
        const index = data.rolesWithPermissions.findIndex(r => r.id === updateRolePermissions.id);
        if (index > -1) {
          data.rolesWithPermissions[index] = updateRolePermissions;
          store.writeQuery({
            query: ROLES_WITH_PERMISSIONS,
            data,
          });
        }
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Permissions for role ${updateRolePermissions.name} was updated successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
