import Btn from '@/components/Btn/Btn.vue';
import Permissions from '@/components/Permissions/Permissions.vue';
import ROLES_QUERY from '@/graphql/queries/roles.gql';

export default {
  name: 'RoleForm',
  components: { Btn, Permissions },
  apollo: {
    roles: {
      query: ROLES_QUERY,
      fetchPolicy: 'cache-first',
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load role list.' } : error;
      },
    },
  },
  props: {
    loading: { type: Boolean, default: false },
    permissionSettings: { type: Object },
    rolesWithPermissions: { type: Array },
  },
  data: () => ({
    role: null,
    roles: [],
  }),
  watch: {
    'permissionSettings.role'(val, oldVal) {
      if (!oldVal || val.id !== oldVal.id) {
        const roleWithPermission =
          this.permissionSettings.role &&
          this.rolesWithPermissions &&
          this.rolesWithPermissions.find(r => this.permissionSettings.role.name === r.name);
        if (roleWithPermission) {
          this.permissionSettings.permissions = roleWithPermission.permissions.map(i => ({
            group: i.group,
            key: i.key,
          }));
        }
      }
    },
  },
  computed: {
    disableBtn() {
      return this.permissionSettings.role === null;
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit', this.user);
      }
    },
  },
};
